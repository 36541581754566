.blockquote.no-italic {
    font-style: normal;
}

/* Edit blockquote line color */
.blockquote.bq-danger {
    border-left: 4px solid #e40505;
}

.custom-carousel-icon {
    filter: invert(100%);
}

.custom-img-size {
    width: auto;
    /* Adjust the width as needed */
    height: 60px;
    /* Maintain aspect ratio */
}